@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

/* .animate-slide-up {
    animation: slideUp 0.5s ease forwards;
} */

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.animate-zoom-in {
    animation: zoomIn 0.5s ease forwards;
}
