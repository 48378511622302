
.from-left,.from-right{
    transition: opacity 250ms ease-in, transform 400ms ease-in;
    opacity: 0;
}
.from-left.appear,.from-right.appear{
    transform: translateX(0);
    opacity: 1;
}
.from-bottom {
    transition: opacity 250ms ease-in, transform 400ms ease-in;
    opacity: 0;
    transform: translateY(100%); /* Starts the element 100% below its original position */
}

.from-bottom.appear {
    transform: translateY(0);
    opacity: 1;
}
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px); /* Slide slightly from the bottom */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-slide-up {
    animation: slide-up 0.5s ease forwards;
  }
  

.animate-slide-up {
    animation: slideUp 0.5s ease in forwards;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.animate-zoom-in {
    animation: zoomIn 0.5s ease forwards;
}
.transition-transform {
  transition: transform 0.3s ease;
}

@keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-slideIn {
    animation: slideIn 0.5s ease forwards;
  }

  @keyframes swing {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(15deg); }
    50% { transform: rotate(-10deg); }
    75% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
  }
  
  .animate-swing {
    animation: swing 1s ease infinite;
  
  }

  @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    80% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.animate-rotate {
  animation: rotate 1s ease infinite;

}

  @keyframes zoomrotate {
    0% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
        
    }
    20% {
        opacity: 1;
        transform: scale(1.2) rotate(-10deg);
        
    }
    50% {
        opacity: 1;
        transform: scale(1.4) rotate(-20deg);
       
    }
    80% {
        opacity: 1;
        transform: scale(1.2) rotate(-10deg);
        
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
       
    }
}

.animate-zoomrotate {
    animation: zoomrotate 1s ease infinite;
}


  @keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.animate-shake {
  animation: shake 0.5s infinite;
}
@keyframes tada {
    0% { transform: scale(1); }
    10%, 20% { transform: scale(0.9) rotate(-3deg); }
    30%, 50%, 70%, 90% { transform: scale(1.1) rotate(3deg); }
    40%, 60%, 80% { transform: scale(1.1) rotate(-3deg); }
    100% { transform: scale(1) rotate(0); }
  }
  
  .animate-tada {
    animation: tada 1s infinite;
  }
/* Initial state: Hidden and translated down */
/* Add this CSS to your Common.css or a relevant CSS file */
.box-container{
  box-shadow: 0px 12px 16px -4px #1018281A;
}
.card {
  transition: transform 0.3s ease;
}

.zoom-out {
  transform: scale(0.9); /* Adjust the scale factor as needed */
}


.card-1{
 
  top: 0;
}
.card-2{

  top: 35px;
}
.card-3{

  top: 70px;
}
.card-4{

  top: 105px;
}
@media (max-width: 992px) {
  .card-1,
  .card-2,
  .card-3,
  .card-4 {
    top: auto; /* Reset top property */
  }
}
/* .hidden-container {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.visible-container {
  opacity: 1;
  transform: translateY(0);
}
.testimonial-animation {
  transition: transform 0.5s ease;
}

.testimonial-animation.visible-container {
  transform: rotateY(0deg);
} */