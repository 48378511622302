
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
} 

.m-scroll {
    overflow: hidden;
    /* height: 100%; */
    white-space: nowrap;
    animation: scrollText 50s infinite linear;
    margin: 0;
    font-size: 0;
    display: flex;
    justify-content: space-between;
    width: fit-content;
  }
  
  .scrollimg {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: white;
    -webkit-filter: grayscale(100%);
  }
  
  @keyframes scrollText {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  